<template>
<el-drawer
  title="文件预览"
  append-to-body
  size="1200px"
  :visible.sync="dialogVisible"
  :direction="direction"
  :before-close="handleClose">
  <iframe :srcdoc="htmlData" width="100%" height="1000" />
</el-drawer>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      htmlData:'',
      direction: "rtl",
    };
  },
  props: {
    client: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    open(id) {
      this.dialogVisible = true
      this.preview(id)
    },
    async preview(id) {
      let { code = 200, msg, data } = await this.$http.Post(this.client ? this.$api.clientPreviewAttachment : this.$api.previewAttachment, { id:id });
      if (code == 200) {
        this.htmlData = data;
      } else  {
        this.$message.error(msg)
        setTimeout(() => {
          this.dialogVisible = false
        }, 500)
      }
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>