import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'pagination',
  props: {
    pageSizes: {
      type: Array,
      "default": function _default() {
        return [10, 20, 50, 100];
      }
    },
    pageSize: {
      type: Number,
      "default": 10
    },
    pageNum: {
      type: Number,
      "default": 1
    },
    total: {
      type: Number,
      "default": 100
    },
    pagerCount: {
      type: Number,
      "default": 7
    },
    layout: {
      type: String,
      "default": 'total, prev, pager, next, sizes, jumper'
    },
    small: {
      type: Boolean,
      "default": false
    },
    // 是否滚动到顶部
    isScrollTop: {
      type: Boolean,
      "default": true
    }
  },
  data: function data() {
    return {};
  },
  methods: {
    sizeChange: function sizeChange(val) {
      this.$emit('sizeChange', val);
    },
    currentChange: function currentChange(val) {
      this.$emit('currentChange', val);
      if (this.isScrollTop) document.querySelector('.main-content').scrollTop = 0;
    }
  },
  mounted: function mounted() {
    // console.log("pageSizes"+this.pageSizes)
  }
};