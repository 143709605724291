<template>
  <el-drawer
    title="项目详情"
    size="900px"
    :visible.sync="showDetail"
    :direction="direction"
    :before-close="handleClose"
  >
  <div class="head">
    <div class="head-item">
      <p>项目名称:{{detailInfo.enterpriseName}}</p>
      <!-- <div>
        <el-button type="text" @click="handleCollect">{{detailInfo.collected == 1 ? '取消收藏':'收藏'}}</el-button>
      </div> -->
    </div>
    <div class="head-item2">
      <p>行业:{{detailInfo.industryName}}</p>
      <p>地区:{{detailInfo.cityName}}</p>
    </div>
    <div class="head-item2">
      <p>营收:{{detailInfo.businessIncomeName||'--'}}</p>
      <p>净利规模:{{detailInfo.netProfitName||'--'}}</p>
    </div>
  </div>
  <div class="content">
    <el-tabs v-model="activeName" @tab-click="handleDocClick">
      <el-tab-pane label="项目BP" name="1"></el-tab-pane>
      <el-tab-pane label="访谈纪要" name="2"></el-tab-pane>
      <el-tab-pane label="立项报告" name="3"></el-tab-pane>
      <el-tab-pane label="尽调报告" name="4"></el-tab-pane>
      <el-tab-pane label="投决报告" name="5"></el-tab-pane>
      <el-tab-pane label="联系人" name="6"></el-tab-pane>
    </el-tabs>

  </div>
    <div v-show="showAttachments.length > 0 && activeName != 6" v-for="item in showAttachments" :key="item.id" class="attachmentsItem">
      <div class="left">
        <img :src="getIcon(item.fileType)">
        <div class="right">
          <p >文件名：{{item.fileName}}</p>
          <p style="margin-top:5px;">大小:{{item.fileSize|getFileSize}}</p>
        </div>
      </div>
      <div class="right-btn">
        <!-- 管理员可以下架附件 -->
        <el-button v-if="[1,3].includes(user.userType)" type="text" @click="editName(item)">修改附件名称</el-button>
        <el-button v-if="user.userType == 1" type="text" @click="publicOperation(item)">{{ item.isPublic ? '不公开附件' : '公开附件' }}</el-button>
        <el-button v-if="user.userType == 1" type="text" @click="deleteAttachment(item)">{{ item.fileStatus == 2 ? '下架' : '上架' }}</el-button>
        <el-button v-if="user.userType == 1" type="text" @click="downloadAttachment(item)">下载</el-button>
        <el-button type="text" @click="preview(item.id)">在线预览</el-button>
      </div>
    </div>
    <div v-if="activeName == 6">
      <div v-for="item in showAttachments" :key="item.id" class="enterpriseConcat">
        <el-button v-if="user.userType == 1"  type="primary" siize="small" @click="publicOperation(item)">{{ item.isPublic ? '不公开联系人' : '公开联系人' }}</el-button>
        <div class="line">
          <span class="name">姓名:</span>
          {{item.enterpriseConcatName}}
        </div>
        <div class="line">
          <span class="name">手机号:</span>
          {{item.enterpriseConcatPhone}}
        </div>
        <div class="line">
          <span class="name">职务:</span>
          {{item.enterpriseConcatPosition || '---'}}
        </div>
        <div class="line line-card">
          <span class="name">名片:</span>
          <img style="width: 60%;" :src="item.enterpriseConcatBusinessCard" alt="">
        </div>
      </div>
    </div>
  
  <el-empty v-if="showAttachments.length == 0" description="暂无数据"></el-empty>
  <div class="upload-file" v-if="isShowUpload && activeName != 6">
    <el-upload
      class="upload-demo"
      action="#"
      :auto-upload="false"
      :on-change="handleFileChange"
      :show-file-list="false"
      style="text-align: center"
    >
      <el-button size="small" type="primary" plain>上传文件</el-button>
    </el-upload>
  </div>
    <!-- <div class="footer">

      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="handleClose">确 定</el-button>
    </div> -->
    <previewDialog ref="previewDialog"/>
  </el-drawer>
</template>
<script>
import previewDialog from "./previewDialog.vue"
import { mapState } from "vuex";
// import { download } from '@/public/utils.js'
export default {
  filters: {
    getFileSize(value) {
      return Math.floor(value/1024/1024 * 100)/100 +'MB';
    }
  },
  components: {
    previewDialog
  },
  computed: {
    ...mapState(["user"]),
    //当前展示的附件
    showAttachments() {
      // console.log("this.detailInfo.investProje ctAttachments---".this.detailInfo?.investProjectAttachments)
      return this.detailInfo?.investProjectAttachments?.filter(i=>i.attachmentType == this.activeName) || []
    },
    isShowUpload() {
      return this.showAttachments.length < 2
    }
  },
  data() {
    return {
      htmlData:'',
      pdfIcon: require('@/assets/fileIcon/pdf.png'),
      docIcon: require('@/assets/fileIcon/word.png'),
      pptIcon: require('@/assets/fileIcon/ppt.png'),
      projectId:'',
      detailInfo:{},
      activeName: '1',
      showDetail: false,
      direction: "rtl",
      
    };
  },
  methods: {
    // 1PDF、2word、3PPT
    getIcon(fileType){
      switch(fileType){
        case 1:
          return this.pdfIcon
        case 2:
          return this.docIcon
        case 3:
          return this.pptIcon
      }
    },
    async handleCollect() {
      const params = {
        collectType:1,
        projectId:this.projectId,
        projectName:this.detailInfo.enterpriseName
      }
      if(this.detailInfo.collected == 1) {
        let res = await this.$http.DeleteByData(this.$api.cancelCollect,  params );
        if (res.code == 200) {
          this.$message.success('取消收藏成功')
        }
      }else {
        let res = await this.$http.Post(this.$api.addCollectRecord,  params );
        if (res.code == 200) {
          this.$message.success('收藏成功')
        }
      }
      this.detailGetData(this.projectId)
    },
    // 下架
    deleteAttachment({ id, fileStatus }) {
      this.$confirm(`确定要${fileStatus == 2 ? '下架' : '上架'}附件吗？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          await this.$http.Post(this.$api.updateAttachmentStatus, {
            id: id,
            fileStatus: fileStatus == 2 ? 3 : 2,
          });
          this.detailGetData(this.projectId);
          this.$message({
            type: 'success',
            message: '操作成功'
          }); 
          this.$parent.handleSearch()
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    getCoinName(type) {
      switch(type) {
        case 1:
          return '铁币'
        case 2:
          return '铜币'
        case 3:
          return '银币'
        case 4:
          return '金币'
        case 5:
          return '黄钻'
        case 6:
          return '红钻'
      }
    },
    // 下载
    async downloadAttachment(item) {
      // const params = {
      //   id:item.id,
      //   attachmentType:item.attachmentType,
      // }
      // let {data} = await this.$http.Post(this.$api.downloadAttachment,  params );
      // this.$confirm(`下载需要消耗1个${this.getCoinName(item.attachmentType)},确定要下载吗?`, '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      //   }).then(async () => {
          
          const res = await this.$http.Post(this.$api.downloadAttachment, {
            attachmentId:item.id,
          });
          
          const notify = this.$notify({
            title: '下载中',
            message: '请稍等...',
            duration: 0
          });
          const { data: { data, filename } } = await this.$http.DownLoadFile(this.$api.downloadFileByKey, {
            fileKey: res.data
          })
          // download(data, filename)
          const methods = new this.Global()
          // console.log(data)
          methods.downloadBlob(data, decodeURIComponent(filename))
          this.$message({
            type: 'success',
            message: '操作成功'
          }); 
          notify.close()
          this.$parent.handleSearch()
        // }).catch((e) => {
        //   console.log(e)
        //   this.$message({
        //     type: 'info',
        //     message: '已取消'
        //   });          
        // });
    },
    async preview(id) {
      this.$refs.previewDialog.open(id)
      // let res = await this.$http.Post(this.$api.previewAttachment, { id });
      // if (res.code == 200) {
      //   console.log('result====',res.data)
      //   this.htmlData = res.data
      //   this.$router.push({path:'/preview',query:{id:id}})
      // }
      
      // let url = encodeURIComponent(docUrl)
      // let officeUrl = 'http://view.officeapps.live.com/op/view.aspx?src='+url
      // window.open(officeUrl,'_target')

    },
    publicOperation(item) {
      this.$msgbox({
        title: '提示',
        message: `确定${item.isPublic ? '不公开' : '公开'}附件吗?`,
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            this.$http.Post(this.$api.updateAttachment, {
              id:  item.id,
              isPublic: item.isPublic ? 0 : 1
            }).then(() => {
              done();
              instance.confirmButtonLoading = false;
              this.detailGetData(this.projectId)
            })
          } else {
            done();
          }
        }
      })
    },
    // 修改附件名称
    editName(item) {
      const value = (item.fileName || '').match(/^(.*?)(\.[^.]*)$/)
      this.$prompt('请输入附件名称', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue: value[1],
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              const { inputValue } = instance
              if (!inputValue) {
                return this.$message.warning('请输入附件名称')
              }
              instance.confirmButtonLoading = true;
              instance.confirmButtonText = '执行中...';
              this.$http.Post(this.$api.updateAttachment, {
                id:  item.id,
                fileName: `${inputValue}${value[2]}`
              }).then(() => {
                done();
                instance.confirmButtonLoading = false;
                this.detailGetData(this.projectId)
              })
            } else {
              done()
            }
          }
        })
    },
    // 详情调用接口方法
    async detailGetData(id) {
      let res = await this.$http.Get(this.$api.projectDetail, { id });
      if (res.code == 200) {
        console.log(res,'res-------')
        this.detailInfo = res.data||{}
      }
    },
    // 获取附件数
    async getAttachmentNum(type) {
      let res = await this.$http.Get(this.$api.getAttachmentCount, {
        projectId: this.projectId,
        attachmentType: type,
      });
      if (res.code == 200) {
        this.attachmentNum = res.data.bpCount;
      }
    },
    handleDocClick() {
      console.log('tab切换了===',this.activeName)
    },
    handleClose() {
      this.showDetail = false;
    },
    open(id) {
      this.projectId = id
      this.showDetail = true;
      this.detailGetData(id)
      
    },
    // 详情内上传文件
    async handleFileChange(file) {
      let fd = new FormData();
      fd.append("file", file.raw);
      // 上传文件
      const res = await this.$http.Post(this.$api.clientUploadAttachment, fd);
      let obj = {};
      obj.attachmentType = Number(this.activeName);
      obj.fileType = res.data.fileType || 1;
      obj.fileSize = res.data.fileSize;
      obj.fileName = res.data.originName;
      obj.fileUrl = res.data.fileUrl;
      let res1 = await this.$http.Post(this.$api.clientInvestProjectUpload, {
        id: this.projectId,
        investProjectAttachments: [obj],
      });
      if (res1.code == 200) {
        this.$message.success("上传附件成功！");
      }
      this.detailGetData(this.projectId);
      // this.getAttachmentNum(this.activeName);
    },
  },
};
</script>
<style lang='less' scoped>
/deep/ .el-drawer__header{
  padding: 20px 16px;
  border-bottom: 1px solid #d2d0d0;
  margin-bottom: 0px;
}
.footer {
  z-index: 99;
  width: 100%;
  padding: 20px 24px;
  margin-top: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  border-top: 1px solid #e7e7e7;
  display: flex;
  justify-content: flex-end;
  background-color: #fff;
}
.head {
  border-radius: 4px;
  height: 100px;
  padding: 16px;
  p{
    line-height: 30px;
  }
  .head-item{
    display: flex;
    justify-content: space-between;
  }
  .head-item2{
    display: flex;
    >p{
      width: 400px;
    }
  }

}
.enterpriseConcat{
  margin: 20px auto;
  padding: 0 20px;
  .line{
    margin: 20px 0;
  }
  .line-card{
    display: flex;
    align-items: start;
    img{
      margin-left: 20px;
    }
  }
}
.content {
  padding: 16px;
}
.attachmentsItem {
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  background-color: #f1f5f7;
  border-radius: 4px;
  padding: 12px 16px;
  margin-bottom: 24px;
  margin-left: 24px;
  margin-right: 24px;
  .left {
  flex: 1;
    img {
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }
    display: flex;
    align-items: center;
  }
  .right-btn{
  }
}
</style>