import "core-js/modules/es.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pagination from "./pagination/index.vue";
export default {
  components: {
    pagination: pagination
  },
  props: {
    tableData: {
      // 表格数据
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    tableColumns: {
      // 表头数据
      type: Array,
      "default": function _default() {
        return [];
      }
    },
    tableHeight: {
      // 表格高度
      type: [Number, String],
      "default": function _default() {
        return null;
      }
    },
    selectionFixed: {
      // 列固定
      type: Boolean || String,
      "default": function _default() {
        return false;
      }
    },
    fixed: {
      // 列固定
      type: Boolean || String,
      "default": function _default() {
        return false;
      }
    },
    selection: {
      // 勾选框
      type: Boolean,
      "default": function _default() {
        return false;
      }
    },
    noData: {
      type: String,
      "default": function _default() {
        return "暂无数据";
      }
    },
    total: {
      // 总页数
      type: Number || String,
      "default": function _default() {
        return 0;
      }
    },
    page: {
      // 当前页
      type: Number || String,
      "default": function _default() {
        return 1;
      }
    },
    rows: {
      // 页码
      type: Number || String,
      "default": function _default() {
        return 10;
      }
    },
    tableLoading: {
      type: Boolean,
      "default": function _default() {
        return false;
      }
    },
    tableBorder: {
      type: Boolean,
      "default": false
    },
    // selectDisableRoom: {
    //   type: Function,
    //   default:()=>{
    //     return
    //   }
    // }
    isPagination: {
      // 是否显示分页
      type: Boolean,
      "default": function _default() {
        return true;
      }
    },
    pageSizes: {
      type: Array
    },
    // 是否滚动到顶部
    isScrollTop: {
      type: Boolean,
      "default": true
    }
  },
  data: function data() {
    return {
      selectData: []
    };
  },
  computed: {},
  mounted: function mounted() {},
  methods: {
    // 排序
    handleSortChange: function handleSortChange(val) {
      this.$emit("handleSortChange", val);
    },
    toggleSelection: function toggleSelection() {
      for (var i = 0; i < this.tableData.length; i++) {
        for (var j = 0; j < this.selectData.length; j++) {
          if (this.tableData[i].id === this.selectData[j].id) {
            this.$refs.multipleTable.toggleRowSelection(this.tableData[i], true);
          }
        }
      }
    },
    getRowKey: function getRowKey(row) {
      return row.id;
    },
    selectDisableRoom: function selectDisableRoom(row) {
      if (row.select) {
        return false;
      }
      return true;
    },
    handelData: function handelData(val) {
      if (val == "0") {
        return "0";
      } else if (val) {
        return val;
      }
      return "--";
    },
    // 多选事件
    handleSelectionChange: function handleSelectionChange(val) {
      this.selectData = val;
      this.$emit("handleSelectionChange", val);
    },
    // 清除多选
    handleRemoveSelectionChange: function handleRemoveSelectionChange() {
      var _this = this;
      this.$nextTick(function () {
        var _this$$refs$multipleT;
        (_this$$refs$multipleT = _this.$refs.multipleTable) === null || _this$$refs$multipleT === void 0 || _this$$refs$multipleT.clearSelection();
      });
    },
    // 切换当前一页展示多少条
    handleSizeChange: function handleSizeChange(val) {
      this.$emit("handleChange", {
        pageSize: val,
        pageNum: 1
      });
      this.toggleSelection();
    },
    // 翻页
    handleCurrentChange: function handleCurrentChange(val) {
      this.$emit("handleChange", {
        pageNum: val,
        pageSize: this.rows
      });
      this.toggleSelection();
    },
    resetScrollBar: function resetScrollBar() {
      var _this2 = this;
      this.$nextTick(function () {
        _this2.$refs.multipleTable.bodyWrapper.scrollLeft = 0;
      });
    },
    doLayout: function doLayout() {
      var _this3 = this;
      this.$nextTick(function () {
        var _this3$$refs$multiple;
        (_this3$$refs$multiple = _this3.$refs.multipleTable) === null || _this3$$refs$multiple === void 0 || _this3$$refs$multiple.doLayout();
      });
    }
  }
};